import { Tokens } from '@/amplify/types';
import { pushService } from '@/services/push-service.ts';
import { traceError } from '@/utils/trace-error';
import { setUser, startSpan } from '@sentry/react';
import { signOut } from 'aws-amplify/auth';
import { cognitoUserPoolsTokenProvider } from 'aws-amplify/auth/cognito';
import mixpanel from 'mixpanel-browser';

type SessionData = {
  tokens?: Tokens;
};

let sessionDataPromise: Promise<SessionData> | undefined;

let forceRefresh = false;

export const invalidateSession = () => {
  sessionDataPromise = undefined;

  // force refresh needed to update preferred username after user selects one during signup
  forceRefresh = true;
};

const fetchSession = async () => {
  const tokens = (await startSpan({ name: 'ui.get_tokens' }, () =>
    cognitoUserPoolsTokenProvider.getTokens({
      forceRefresh,
    }),
  )) as Tokens | undefined;

  forceRefresh = false;

  if (tokens?.idToken?.payload.sub) {
    mixpanel.identify(tokens.idToken.payload.sub);
    mixpanel.people.set({
      $name: tokens.idToken.payload.preferred_username,
      $email: tokens.idToken.payload.email,
    });

    setUser({
      id: tokens.idToken.payload.sub,
      email: tokens.idToken.payload.email,
      username: tokens.idToken.payload.preferred_username,
    });
  }

  return { tokens };
};

export const getAuthorizedData = () =>
  (sessionDataPromise ??= fetchSession())
    .catch(async (err) => {
      if (err.name === 'NetworkError') {
        throw err;
      }

      traceError(new Error('Failed to fetch session data', { cause: err }));

      try {
        const subscription = await pushService.getSubscription();
        if (subscription) await subscription.unsubscribe();
      } catch (err) {
        traceError(
          new Error(
            'Failed to unsubscribe from push notifications on token expire',
            { cause: err },
          ),
        );
      }

      await signOut();

      return {
        tokens: undefined,
      } satisfies SessionData;
    })
    .finally(() => {
      sessionDataPromise = undefined;
    });
